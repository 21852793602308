<template>
    <div class="header_wrap position-relative">
        <header class="title_header hstack justify-content-between px-2 max-w-px-768 position-fixed top-0 w-100" :class="{'on' : has_header_bg}">
            <div>
                <button @click="handleBack" class="btn">
                    <i class="fal fa-chevron-left fs-px-20"></i>
                </button>
                <!-- <i class="fas fa-user-circle fs-px-28 text-gray-500" v-if="!hide_profile"></i> -->
            </div>
            <div class="tit text-truncate fs-px-20 position-absolute top-50 start-50 translate-middle">{{title}}</div>
            <!-- <router-link to="/">
                <i class="fas fa-home fs-px-23 text-gray-600"></i>
            </router-link> -->
        </header>
    </div>
</template>

<script>
export default {
    name: 'TitleHeader',
    props:{
        title:{
            type:String,
            default:'',
        },
        link:{
            type:String,
            default:'',
        },
        hide_profile:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            has_header_bg: false
        }
    },
    methods: {
        handleBack(){
            if(this.link == '' || this.link == null ){
                this.goBack();
            }else if(this.link === 'transitionBack'){
                this.$emit('back');
            } else {
                this.goLink();
            }
        },
        goBack(){
            this.$router.go(-1);
        },
        goLink(){
            this.$router.push(this.link);
        },
        HeaderBg(){
            if(window.scrollY > 60){
                this.has_header_bg = true;
            } else {
                this.has_header_bg = false;
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.HeaderBg);
    },
    destroyed(){
        window.removeEventListener('scroll', this.HeaderBg);
    },
}
</script>

<style lang="scss" scoped>
.header_wrap{
    padding-bottom: 60px;
    z-index: 99;
}

.title_header{
    transition: all 0.2s;

    &.on{
        background-color: #ffffff;
    }
}

.tit{
    max-width: 70%;
}
</style>
