<template>
  <header v-show="show" class="bg-main max-w-px-768">
    <h2 class="px-3 text-white">{{title}}</h2>
    <router-link to="/my/coupon-add" class="btn p-0 search">
      <!-- <img src="@/assets/img/qrcode_scan.svg" alt="큐알코드 스캔" height="26"> -->
      </router-link>
  </header>
</template>

<script>
export default {
  data(){
    return{
      show: true,
    }
  },
  created() {
    this.$EventBus.$on('HideHeader', () => {
      this.show = false;
    }),
    this.$EventBus.$on('ShowHeader', () => {
      this.show = true;
    })
  },
  props:{
    title:{
      type:String,
    }
  }
}
</script>

<style lang="scss" scoped>
header{
    // position: fixed;
    position: relative;
    width: 100vw;
    // 100%->100vw로 변경. 320px 이하 해상도에서 viewport보다 크기가 커져서.
    height: 75px;
    display: flex;
    align-items: center;
    left: 0;
    top:0;
    z-index: 99;
    // background-color: #fff;
    // box-shadow:0 3px 10px 0px #0000001f;
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        img{
            height: 36px;
        }
    }
    .search{
        position: absolute;
        right: 16px;
        font-size: 1.5rem;
    }
}

@media screen and (max-width:321px) {
  header{
    .search{
      right: 15px;
      font-size: 20px;
    }
  }
}
</style>