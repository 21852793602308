<template>
    <div class="alert-container position-fixed top-0 start-0" :class="add_class">
        <div class="dimm w-100 h-100 bg-dark bg-opacity-50 position-absolute top-0 start-0" @click="close()"></div>
        <div class="flex-center-center w-100 min-h-vh-100">
            <div class="content bg-body rounded-2 text-center">
                <div class="inner p-4">
                    <div v-html="msg"></div>
                </div>
                <div class="border-gray-relative-500 flex-center-center pb-3 gap-2">
                    <button class="py-3 fs-px-12 fw-bold btn bg-gray-100 w-50 cancel_btn" v-if="is_confirm" @click="cancel()">{{cancel_btn_txt}}</button>
                    <button class="py-px-12 btn btn-transparent bg-main text-white w-50 ok_btn" @click="ok()">{{ok_btn_txt}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        idx: {
            type: Number
        },
        msg: {
            type: String
        },
        is_confirm: {
            type: Boolean,
            default: false
        },
        ok_btn_txt: {
            type: String,
            default: '확인'
        },
        cancel_btn_txt: {
            type: String,
            default: '취소'
        },
        add_class: {
            type: String,
            default: ''
        },
    },
    methods:{
    },
}
</script>

<style lang="scss" scoped>
    .alert-container{
        width: 100vw !important;
        max-width: 100vw !important;
        height: 100vh;
        z-index: 9999;

        .dimm{
            animation: fadeIn 0.2s;
        }
        .content{
            width: 90%;
            max-width: 400px;
            min-width: 260px;
            transform-origin: center;
            animation: zoomin 0.2s;
            animation-fill-mode: forwards;
            
            .border-top{
                border-width: 0.5px !important;
            }
        }
    }
    @keyframes fadeIn{
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes zoomin{
        from { transform: scale(0.8); }
        to { transform: scale(1); }
    }
</style>