import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'light',
    login: false,
    token : '',
    nick : '',
    email : '',
    show_header_nav: true,
    score_guide: true,
  },
  plugins: [createPersistedState()],
  mutations: {
    SETLOGIN(state, mem_info) {
      
      state.login = true;
      state.token = mem_info.token;
      state.nick = mem_info.nick;
      state.email = mem_info.email;



    },
    SETLOGOUT(state) {
      state.token = '';
      state.login = false;
      state.nick = '';
      state.email = '';
    },

    scoreGuide(state, value){
      state.score_guide = value;
    },
    loadSong(state,value){
      state.all_songs = value;
    },
    show_header_nav(state, value){
      state.show_header_nav = value;
    },
    moveForward(state, index) {
      if (index > 0 && index < state.reserved_song.length) {
        const temp = state.reserved_song[index];
        state.reserved_song.splice(index, 1);
        state.reserved_song.splice(index - 1, 0, temp);
      }
    },
    removeRecord(state, index){
      state.record_song.splice(index, 1);      
    },
    removeAlert(state, index){
      state.alert_list.splice(index, 1);      
    }
  },
  actions: {
    SETLOGIN({commit}, {mem_info}) {
      commit('SETLOGIN', mem_info);
    },
    SETLOGOUT ({commit}) {
      commit('SETLOGOUT');
    },
    
  },
  modules: {
  }
})
