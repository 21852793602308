<template>
    <div class="loading position-fixed top-0 start-50 translate-middle-x max-w-px-768 bg-black">
        <div class="inner position-relative">
            <h1 class="logo py-4">
                <img src="@/assets/img/logo/bunda logo white.png" alt="logo">
            </h1>
            <div class="border-animation start-50 translate-middle"></div>
            <!-- <h3 class="title py-2">
                번다 노래방
            </h3> -->
            <!-- <img src="@/assets/img/intro_effect.svg" alt="" class="position-absolute start-0 bottom-0"> -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    props:{
        introCheck:{
            type: Boolean,
            // require: true,
            default: true //true > false로 해놓음 작업할때 안보이게
        }
    },
    methods:{ 
    },
    created(){
    }
}
</script>

<style lang="scss">
.border-animation{
    position: fixed;
    top: 41.2%;
    width: 90%;
    height: 0;
    overflow: hidden;
    border-radius: 50%;
    /* padding: 2rem; */
    padding-top: 90%;

    &::before {
        content: "";
        position: absolute;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background: conic-gradient(transparent, #faeeff, transparent 50%);
        animation: rotate 1s ease-out infinite;
      }
    
      &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
        /* background: #000; */
        background-image: url('../../assets/img/bunda_intro.png');
        background-size: 150%;
        background-position: center 36%;
      }
    /* &::before{
        content: "";
        position: absolute;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
    }
    position:relative;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    z-index: 1;
    width: 84%;
    height: 0;
    border: 1px solid red;
    border-radius: 50%;
    padding-top: 84%;
    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
      background: #000;
    }*/
}

@keyframes blink {
    40% {
      opacity:.5;
    }
    80% {
      opacity: 1;
    }
  }
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.loading{
    z-index: 100;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/img/bunda_intro.png');
    background-size: cover;
    background-position: 50% 50%;
}
.loading{
    .inner{
        height: 100vh;
        text-align:center;
    }
    .logo{
        left:50%;
        transform:translateX(-50%);
        position:absolute;
        top:33%;
        z-index: 1;
    }
    .logo img{
        width: 200px;
    }
    .logo i{
        color: #2f76dd;
    }
    .title{
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        top:50%;
        font-size: 2rem;
        font-weight: bold;
        color: #2f76dd;
        font-family:'HS-Regular', sans-serif;
    }
}
</style>