<template>
    <div class="basic_set_list">
        <ul class="basic_list d-flex flex-wrap flex-column">
      
            <li  v-for="(item, index) in list" :key="index" class="d-flex justify-content-between border-bottom px-3 py-px-12 w-100" :class="{ 'bg-select': selectSong && index === selectSong.index }" @click="toggleSelect(item, index)">
                <div class="left d-flex flex-grow-1">
                    <div class="album_info d-flex flex-column justify-content-center">
                        <span class="text-truncate">
                            {{ item.title }}
                            <span class="badge border border-primary text-primary" v-if="item.recommend">추천</span>
                        </span>
                        <small class="text-truncate text-opacity-50 text-body">{{ item.artist }}</small>
                    </div>
                </div>
                <div class="right d-flex gap-2 py-3">
                    <button class="btn badge text-primary" variant="info" v-if="item.remove" @click="RemoveList(item,index)" >
                        <i class="fa-regular fa-trash-can fa-lg" ></i>
                    </button>
                    <button class="btn badge text-primary" :class="{'checked':item.favorites}" @click="favChange(item,index)">
                        <i class="fal fa-star fa-lg" :class="{'fas':item.favorites}"></i>
                    </button>
                </div>
            </li>
        </ul>
        
        <div class="alert alert-success d-flex align-items-center position-fixed max-w-px-768  start-50 translate-middle-x shadow bottom-0 mb-2" :class="{'alert-danger':alertType==='D'}" style="width:95%" role="alert" v-if="showAlert">
            <i class="fas fa-check me-2"></i> 
            <div v-if="alertType2==='reservation'">
                {{alertType==='D'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
            </div>
            <div v-else>
                {{alertType==='D'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
    data(){
        return{
            showAlert: false,
            alertType:'',
            alertType2:'',
        }
    },
    props:{
        list:{
            type:Array
        },
        value: {
            type: Object,
            default: null,
        },
        reservation_opt:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        AlertShow(type,type2){
            this.showAlert = true;
            this.alertType = type;
            this.alertType2 = type2;

            setTimeout(() => {
                this.alertType = '';
                this.alertType2 = '';
                this.showAlert = false;
            }, 2000);
        },
        RemoveList(item,index){
            const code = item.code;
            const nation = item.nation;
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/RemoveReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.AlertShow('D','reservation');
                            this.selectSong = null;    
                            this.list.splice(index,1);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        favChange(item,index){
            
            const fav = item.favorites;
            const code = item.code;
            const nation = item.nation;
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/favChange',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            if(fav ==true){
                                item.favorites = false;
                                const path = this.$route.path;
                                if(path =='/favorite'){
                                    this.selectSong = null;    
                                    this.list.splice(index,1);
                                }
                                this.AlertShow('D','favorite');
                                
                            }else{
                                item.favorites = true;
                                this.AlertShow('A','favorite')
                            }
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        toggleSelect(item, index) {
            if (this.selectSong && this.selectSong.index === index) {
                this.selectSong = null;
            } else {
                this.selectSong = { item, index };
            }
        },
        
    },
    computed:{
        selectSong: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    }
}
</script>

<style lang="scss" scoped>
    .basic_set_list .alert{
        z-index: 100;
    }
    .bg-select{
        background-color: #ff57001a;
    }
</style>