<template>
    <div class="position-fixed w-100 max-w-px-768 top-0 start-50 translate-middle-x">
        <Header :title="title" class="w-100 top-0 start-50 translate-middle-x"></Header>
        <!-- <Reservation></Reservation> -->
        <div class="search_input position-relative w-100 bg-dark start-0">
            <div class="d-flex category py-3 justify-content-around">
                <button class="btn rounded-circle bg-white fs-px-14" @click="$router.push('/list/recent')" :class="{'bg-main': selected_idx==0}">NEW</button>
                <button class="btn rounded-circle bg-white" @click="$router.push('/home')" :class="{'bg-main': selected_idx==1}"><i class="fas fa-search"></i></button>
                <button class="btn rounded-circle bg-white" @click="$router.push('/favorite')" :class="{'bg-main': selected_idx==2}"><i class="fas fa-star"></i>
                </button>
                <button class="btn rounded-circle bg-white" @click="$router.push('/frequent')" :class="{'bg-main': selected_idx==3}"><i class="fas fa-plus"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

export default {
    components:{
        Header
    },
    data(){
        return{
            selected_idx: 0,
        }
    },
    methods:{
        
    },
    computed: {
        title(){
            switch (this.$route.path) {
                case '/list/recent':
                    this.selected_idx = 0;
                    return '최신곡'
            
                case '/home':
                    this.selected_idx = 1;
                    return '곡 검색'
            
                case '/favorite':
                    this.selected_idx = 2;
                    return '즐겨찾기'
            
                case '/frequent':
                    this.selected_idx = 3;
                    return '예약곡'
            
                default:
                    return ''
            }
        }
    }

}
</script>

<style>

</style>