import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import { BModal } from 'bootstrap-vue'
import { BAlert } from 'bootstrap-vue'
import alert_js from "./assets/js/alert.js";
import axios from "./axios.js"

import VueCookies from 'vue-cookies'


Vue.use(BootstrapVue)

Vue.use(alert_js);
Vue.component('b-modal', BModal)
Vue.component('b-alert', BAlert)
Vue.config.productionTip = false;
Vue.prototype.$http = axios
Vue.prototype.$EventBus = new Vue();
Vue.use(VueCookies, { expires: '7d'})



new Vue({
  router,
  store,
  BootstrapVue,
  render: h => h(App)
}).$mount('#app')
